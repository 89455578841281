import { TableCell, Box, Typography } from "@mui/material";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";

interface Props {
  trend: number;
}

export const TrendColumn = (props: Props) => {
  const trendImg: string =
    props.trend !== undefined && props.trend > 0
      ? "/img/trend-up-20240109.svg"
      : "/img/trend-down-20240109.svg";

  return (
    <TableCell>
      <Box display={"flex"}>
        <img
          className={props.trend < 0 ? "status" : undefined}
          src={trendImg}
          alt="trend"
        />

        <Typography
          variant="text2"
          color={
            props.trend === 0
              ? undefined
              : props.trend > 0
              ? "var(--red)"
              : "var(--status)"
          }
        >
          {props.trend > 0 && "+"}
          {`${NumberFormatterUtils.numberFormat(props.trend)}%`}
        </Typography>
      </Box>
    </TableCell>
  );
};
