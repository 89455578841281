import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
} from "@mui/material";
import { useContext, useState } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { Trans, useTranslation } from "react-i18next";
import AddAssetModal from "./AddAssetModal";
import { SubOrganizations } from "../../models/SubOrganization";
import TableApprovalStatus from "../emission/data-table/TableApprovalStatus";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { DROPDOWN_EMISSION_GROUP } from "../../constants/dropdown";
import { useAuth } from "../../contexts/UserContext";
import alertService from "../../components/alert/alertService";
import { EmissionGroup } from "../../models/EmissionGroup";
import { tableStyle } from "../emission/data-table/EmissionDataTableStyle";
import { CustomPagination } from "../../components/datagrid/CustomPagination";

const getLocation = (subOrganization?: SubOrganizations) => {
  const address = subOrganization?.location?.address ?? "";
  const district = subOrganization?.location?.district ?? "";
  const city = subOrganization?.location?.city ?? "";
  return [address, district, city].join(" ");
};

const Assets = () => {
  const context = useContext(OrganizationContext);
  const { isAdmin } = useAuth();
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [moreAnchor, setMoreAnchor] = useState<null | HTMLElement>(null);
  const [rowSelect, setRowSelect] = useState<any>();
  const openMore = Boolean(moreAnchor);
  const { t } = useTranslation(["common", "input"], { nsMode: "fallback" });

  if (!context) {
    throw Error();
  }

  const showSubOrganization = context.selectedSubOrganization === undefined;
  const selectedSubOrganization = context.subOrganizations?.find(
    (item) => item.id === context.selectedSubOrganization
  );

  const rows =
    context.filteredAssets?.map((item) => {
      const subOrganization = context.subOrganizations?.find(
        (sub) => sub.name === item.suborganizationName
      );

      return {
        id: item._id,
        name: item.name,
        assetType: item.assetType,
        emissionGroup: item.emissionGroup,
        subOrganization: subOrganization,
        location: getLocation(subOrganization),
        status: item.assetStatus,
        siteName: item.siteName,
      };
    }) ?? [];

  const organizationsMenuItem = [
    {
      nameTH: context.organization?.name ?? "",
      nameEN: context.organization?.name ?? "",
      value: context.organization?._id ?? "",
    },
  ];
  // const emissionGroupMenuItem = DROPDOWN_EMISSION_GROUP.map((item) => {
  //   return {
  //     nameTH: t(item.groupTh),
  //     nameEN: t(item.groupEn),
  //     value: item._id,
  //     disabled: !emissionGroup.includes(item._id),
  //   };
  // }).sort((a, b) => {
  //   if (a.disabled) return 1;
  //   if (b.disabled) return -1;
  //   return 0;
  // });

  const emissionGroupMenuItem = DROPDOWN_EMISSION_GROUP.map((item) => {
    return {
      nameTH: t(item.groupTh),
      nameEN: t(item.groupEn),
      value: item._id,
      disabled: false,
    };
  });

  let subOrganizationsMenuItem: {
    value: string;
    nameTH: string;
    nameEN: string;
  }[];
  if (context.subOrganizations === undefined) {
    subOrganizationsMenuItem = [];
  } else {
    if (context.selectedSubOrganization) {
      const name = selectedSubOrganization?.name ?? "";
      subOrganizationsMenuItem = [
        {
          nameTH: name,
          nameEN: name,
          value: selectedSubOrganization?.id.toString() ?? "",
        },
      ];
    } else {
      subOrganizationsMenuItem = context.subOrganizations?.map((item) => {
        const name = item.name;
        return { nameTH: name, nameEN: name, value: item.id.toString() };
      });
    }
  }

  const assetType = Array.from(
    new Set(context.assets?.map((item) => item.assetType))
  ).map((item) => {
    return {
      nameTH: item,
      nameEN: item,
      value: item,
    };
  });

  const handleOnClickMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setRowSelect(row);

    setMoreAnchor(event.currentTarget);
  };

  const onClickAdd = () => {
    setOpenNew(true);
  };

  const onClickEdit = () => {
    setMoreAnchor(null);
    setOpenNew(true);
  };

  const onClickDelete = async () => {
    setMoreAnchor(null);
    if (rowSelect === undefined) {
      return;
    }
    const isConfirmed = await alertService.confirm(
      <Box>
        {t("Are-You-Sure-Want-To-Delete")}{" "}
        <span style={{ color: "var(--red)" }}>{rowSelect.name}</span>
      </Box>,
      <Trans
        t={t}
        i18nKey="Delete-Asset-Description"
        values={{ id: rowSelect.id }}
        components={{
          span: <span style={{ color: "var(--red)" }} />,
        }}
      />,
      t("Delete"),
      "var(--red)"
    );

    if (isConfirmed) {
      context.deleteAsset(rowSelect.id, rowSelect.subOrganization.id);
    }
    setRowSelect(undefined);
  };

  const onAssetAddClick = (
    organization?: string,
    emissionGroup?: string,
    subOrganization?: string,
    assetType?: string,
    assetName?: string,
    assetStatus?: string,
    site?: string
  ) => {
    if (
      organization === undefined ||
      emissionGroup === undefined ||
      subOrganization === undefined ||
      assetType === undefined ||
      assetName === undefined ||
      assetStatus === undefined ||
      site === undefined
    ) {
      return;
    }
    context.addAsset(
      organization,
      emissionGroup,
      subOrganization,
      assetType,
      assetName,
      assetStatus,
      site
    );
    setOpenNew(false);
  };

  const onAssetEditClick = (
    assetType?: string,
    assetName?: string,
    assetStatus?: string,
    site?: string
  ) => {
    if (
      assetType === undefined ||
      assetName === undefined ||
      assetStatus === undefined ||
      site === undefined
    ) {
      return;
    }
    context.editAsset(
      rowSelect.id,
      rowSelect.subOrganization.id,
      assetType,
      assetName,
      assetStatus,
      site
    );
    setRowSelect(undefined);
    setOpenNew(false);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Asset-Name"),
      width: 160,
      flex: 1,
      disableColumnMenu: true,
      headerAlign: "left",
      valueFormatter: (value) => t(value),
    },
    {
      field: "assetType",
      headerName: t("Asset-Type"),
      width: 150,
      flex: 1,
      disableColumnMenu: true,
      headerAlign: "left",
      valueFormatter: (value) => t(value),
    },
    {
      field: "emissionGroup",
      headerName: t("Emission-Group"),
      width: 160,
      disableColumnMenu: true,
      headerAlign: "left",
      valueFormatter: (value) => {
        try {
          const emission: EmissionGroup | undefined =
            DROPDOWN_EMISSION_GROUP.find((e) => e.emissionGroup === value);
          return t(emission!.groupTh);
        } catch (error) {
          return value;
        }
      },
    },
    {
      field: "subOrganization",
      headerName: t("Sub-Organizations"),
      width: 150,
      disableColumnMenu: true,
      headerAlign: "left",
      valueFormatter: (value, row) => {
        if (value === undefined) {
          return "";
        }
        const subOrganization: SubOrganizations = value;
        return subOrganization.name;
      },
    },
    {
      field: "siteName",
      headerName: t("Site"),
      width: 100,
      disableColumnMenu: true,
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: t("Asset-Status"),
      width: 170,
      disableColumnMenu: true,
      headerAlign: "left",
      align: "center",
      renderCell: (params) => {
        return <TableApprovalStatus status={params.value} by={""} />;
      },
    },
    {
      field: "",
      disableColumnMenu: true,
      sortable: false,
      type: "actions",
      width: 70,
      getApplyQuickFilterFn: undefined,
      headerAlign: "left",
      getActions: (params) => {
        return isAdmin()
          ? [
              <GridActionsCellItem
                key={`${params.row.id}-more`}
                icon={<img src="/img/more.svg" alt="icon-more" />}
                label="more"
                id="basic-button"
                onClick={(event) => handleOnClickMore(event, params.row)}
              />,
            ]
          : [];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: { lg: "100%" },
        boxSizing: "border-box",
        width: "100%",
      }}
    >
      <Stack minHeight={0} height={1} spacing={"40px"}>
        {isAdmin() && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ alignSelf: "end" }}
            onClick={onClickAdd}
            startIcon={<img src="/img/add-circle.svg" alt="add-circle" />}
          >
            {t("Add-Asset")}
          </Button>
        )}
        <DataGrid
          columns={columns}
          rows={rows}
          rowHeight={48}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columnVisibilityModel={{
            subOrganization: showSubOrganization,
            location: showSubOrganization,
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20]}
          // sx={tableStyle()}
          sx={{
            width: "100%",
            height: "100%",
            ".css-d8v667-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected":
              {
                backgroundColor: "var(--dark) !important",
              },
            ".MuiDataGrid-iconButtonContainer": {
              visibility: "visible",
              color: "var(--white)",
            },
            ".MuiDataGrid-sortIcon": {
              opacity: "inherit !important",
              color: "var(--black)",
            },
            ".MuiDataGrid-columnHeader": {
              backgroundColor: "var(--gray6)",
            },
            ".MuiDataGrid-columnHeaderTitle": {
              color: "var(--black)",
            },
            ".MuiDataGrid-columnHeaders .MuiDataGrid-filler": {
              backgroundColor: "var(--gray6)",
            },
            ".MuiDataGrid-cell": {
              alignContent: "center",
              lineHeight: "20px",
              position: "relative",
            },
            ".MuiDataGrid-cell:focus": {
              outline: "solid #0B9485 0px !important",
            },
            ".MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
            ".MuiDataGrid-columnSeparator": {
              color: "var(--gray5) !important",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            border: "0px",
            fontSize: "14px",
          }}
          slots={{
            pagination: CustomPagination,
          }}
          localeText={{
            toolbarQuickFilterPlaceholder: `${t("search")}...`,
            noRowsLabel: t("noRowsLabel"),
            noResultsOverlayLabel: t("noResultsOverlayLabel"),
          }}
        />
      </Stack>
      <Menu
        open={openMore}
        anchorEl={moreAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={(_) => {
          setMoreAnchor(null);
          setRowSelect(undefined);
        }}
      >
        <MenuList>
          <MenuItem onClick={onClickEdit}>
            <ListItemIcon>
              <img src="/img/edit-2.svg" alt="edit icon" />
            </ListItemIcon>
            <ListItemText>{t("Edit-Asset")}</ListItemText>
          </MenuItem>
          <Box px={"12px"}>
            <Divider />
          </Box>
          <MenuItem onClick={onClickDelete}>
            <ListItemText
              primary={t("Delete-Asset")}
              primaryTypographyProps={{ color: "var(--red)" }}
            />
          </MenuItem>
        </MenuList>
      </Menu>
      <AddAssetModal
        editItem={rowSelect}
        open={openNew}
        onClose={() => {
          setOpenNew(false);
          setRowSelect(undefined);
        }}
        currentOrganization={context.organization?._id}
        organizationsMenuItem={organizationsMenuItem}
        emissionGroup={emissionGroupMenuItem}
        selectedSubOrganization={selectedSubOrganization?.id.toString()}
        subOrganizationsMenuItem={subOrganizationsMenuItem}
        assetType={assetType}
        onAddClick={onAssetAddClick}
        onEditClick={onAssetEditClick}
      />
    </Box>
  );
};

export default Assets;
