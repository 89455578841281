import { IconButton } from "@mui/material";
import { t } from "i18next";
import { VisuallyHiddenInput } from "../../pages/emission/data-table/EmissionDataTableStyle";
import FileUtils from "../../utils/fileUtlis";
import alertService from "../alert/alertService";
import { MAX_UPLOAD_FILE_SIZE } from "../../constants/form";

type Props = {
  value: string;
  id: string;
  quickUploadFile: (base64: string) => void;
  disabled?: boolean;
};

export const QuickUploadFile = (props: Props) => {
  const uploadFile = (event: any) => {
    const file: any = event.currentTarget.files;

    if (file[0]) {
      if (file[0].size > MAX_UPLOAD_FILE_SIZE) {
        alertService.info(
          t("Maximum-file-size-exceeded", {
            size: (MAX_UPLOAD_FILE_SIZE / 1e6).toFixed(0),
          })
        );

        event.target.value = "";
      } else {
        FileUtils.getBase64(file[0])
          .then(async (res) => {
            props.quickUploadFile(res as string);
          })
          .catch((err) => {
            event.target.value = "";
            alertService.error(t("Invalid-File-Type"));
          });
      }
    }
  };
  return (
    <>
      {props.value !== "" ? (
        <IconButton
          component="label"
          key={props.id + "-icon-file-saved"}
          onClick={() => {
            try {
              window.open(
                URL.createObjectURL(FileUtils.base64ToFile(props.value))
              );
            } catch (error) {
              alertService.info(t("Cannot-Preview-File"));
            }
          }}
        >
          <img src="/img/icon-file-saved.svg" alt="icon-file" />
        </IconButton>
      ) : (
        <IconButton component="label" key={props.id + "-icon-file-unsave"}>
          <img src="/img/icon-file-unsave.svg" alt="icon-file" />
          <VisuallyHiddenInput
            type="file"
            accept="image/*,application/pdf"
            key={props.id + "-visually-hidden-input"}
            name={props.id + "-visually-hidden-input"}
            onChange={uploadFile}
            disabled={props.disabled}
          />
        </IconButton>
      )}
    </>
  );
};
