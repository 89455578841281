import Grid2 from "@mui/material/Unstable_Grid2";
import { t } from "i18next";
import { CustomAutoComplete } from "../../components/input/CustomAutoComplete";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import { THAILAND_GEOGRAPHY } from "../../constants/thailandLocation";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";

interface Props {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  disabled?: boolean;
}
export const AutoLocation = (props: Props) => {
  const city = props.watch("city");

  const district = props.watch("district");

  const subDistrict = props.watch("subDistrict");

  const buildFilterDistrict = () => {
    const filterItem = THAILAND_GEOGRAPHY.states.find(
      (f) => f.stateNameTh === city
    );

    return filterItem
      ? filterItem.districts.map((m) => ({
          nameEN: m.districtNameTh,
          nameTH: m.districtNameTh,
          value: m.districtNameTh,
        }))
      : [];
  };

  const buildFilterSubDistrict = () => {
    const filterState = THAILAND_GEOGRAPHY.states.find(
      (f) => f.stateNameTh === city
    );

    const filterDistrict = filterState?.districts.find(
      (d) => d.districtNameTh === district
    );

    return filterDistrict
      ? filterDistrict.subdistricts.map((s) => ({
          nameEN: s.subdistrictNameTh,
          nameTH: s.subdistrictNameTh,
          value: s.subdistrictNameTh,
        }))
      : [];
  };

  const buildFilterZipcode = () => {
    const filterState = THAILAND_GEOGRAPHY.states.find(
      (f) => f.stateNameTh === city
    );

    const filterDistrict = filterState?.districts.find(
      (d) => d.districtNameTh === district
    );

    const filterSubdistricts = filterDistrict?.subdistricts.find(
      (d) => d.subdistrictNameTh === subDistrict
    );

    if (filterSubdistricts) {
      return [
        {
          nameEN: `${filterSubdistricts.postalCode}`,
          nameTH: `${filterSubdistricts.postalCode}`,
          value: `${filterSubdistricts.postalCode}`,
        },
      ];
    } else {
      return [];
    }
  };

  return (
    <>
      <Grid2 xs={12} md={6}>
        <CustomAutoComplete
          required
          label={t("Country")}
          info={t("Country")}
          name="country"
          control={props.control}
          defaultValue={"ไทย"}
          placeholder={t("Country")}
          rules={{
            required: t("form-reqired-error", {
              param: t("Country"),
            }),
            onChange(event) {
              props.setValue("city", "");
              props.setValue("district", "");
              props.setValue("subDistrict", "");
              props.setValue("postalCode", "");
            },
          }}
          listMenuItem={[
            {
              nameEN: "ไทย",
              nameTH: "ไทย",
              value: "ไทย",
            },
          ]}
          disabled={props.disabled}
        />
      </Grid2>
      <Grid2 xs={12} md={6}>
        <CustomAutoComplete
          required
          label={t("State")}
          info={t("State")}
          name="city"
          control={props.control}
          defaultValue={""}
          placeholder={t("State")}
          rules={{
            required: t("form-reqired-error", {
              param: t("State"),
            }),
            onChange(event) {
              props.setValue("district", "");
              props.setValue("subDistrict", "");
              props.setValue("postalCode", "");
            },
          }}
          listMenuItem={THAILAND_GEOGRAPHY.states.map((m) => ({
            nameEN: m.stateNameTh,
            nameTH: m.stateNameTh,
            value: m.stateNameTh,
          }))}
          disabled={props.disabled}
        />
      </Grid2>
      <Grid2 xs={12} md={6}>
        <CustomAutoComplete
          required
          label={t("district")}
          info={t("district")}
          name="district"
          control={props.control}
          defaultValue={""}
          placeholder={t("district")}
          rules={{
            required: t("form-reqired-error", {
              param: t("district"),
            }),
            onChange(event) {
              props.setValue("subDistrict", "");
              props.setValue("postalCode", "");
            },
          }}
          listMenuItem={buildFilterDistrict()}
          disabled={
            city === undefined || city === "" || props.disabled === true
              ? true
              : false
          }
        />
      </Grid2>
      <Grid2 xs={12} md={6}>
        <CustomAutoComplete
          required
          label={t("Sub-District")}
          info={t("Sub-District")}
          name="subDistrict"
          control={props.control}
          defaultValue={""}
          placeholder={t("Sub-District")}
          rules={{
            required: t("form-reqired-error", {
              param: t("Sub-District"),
            }),
            onChange(event) {
              props.setValue("postalCode", "");
            },
          }}
          listMenuItem={buildFilterSubDistrict()}
          disabled={
            district === undefined || district === "" || props.disabled === true
              ? true
              : false
          }
        />
      </Grid2>
      <Grid2 xs={12} md={6}>
        <CustomAutoComplete
          required
          label={t("Zip-Code")}
          info={t("Zip-Code")}
          name="postalCode"
          control={props.control}
          defaultValue={""}
          placeholder={t("Zip-Code")}
          rules={{
            required: t("form-reqired-error", {
              param: t("Zip-Code"),
            }),
          }}
          listMenuItem={buildFilterZipcode()}
          disabled={
            subDistrict === undefined ||
            subDistrict === "" ||
            props.disabled === true
              ? true
              : false
          }
        />
      </Grid2>
      <Grid2 xs={12} md={6}>
        <InputReactHookForm
          label={t("Address")}
          info={t("Address")}
          name="address"
          placeholder={t("Address")}
          control={props.control}
          defaultValue={""}
          rules={{}}
          disabled={props.disabled}
        />
      </Grid2>
    </>
  );
};
