import { Box } from "@mui/material";
import Layout from "../../../Layout";
import { EconomicIndicatorProvider } from "../../../contexts/EconomicIndicatorContext";
import { TableEconomicIndicator } from "./TableEconomicIndicator";

export const EconomicIndicator = () => {
  return (
    <EconomicIndicatorProvider>
      <Layout>
        <Box
          padding={"40px"}
          bgcolor={"var(--white)"}
          height={"100%"}
          boxSizing={"border-box"}
        >
          <TableEconomicIndicator />
        </Box>
      </Layout>
    </EconomicIndicatorProvider>
  );
};
