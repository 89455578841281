import { t } from "i18next";
import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import {
  TemplateActivities,
  TemplateActivitiesResponse,
} from "../models/TemplateActivities";
import { ResponseTemplateActivities } from "../models/dto/response/ResponseTemplateActivities";

const TemplateActivitiesService = {
  getTemplateActivities: async (): Promise<TemplateActivities[] | null> => {
    try {
      const response = await apiClient.get("/v1/template-activities");

      if (response.status === 200) {
        const result: TemplateActivitiesResponse = response.data;
        return result.results.templateActivities;
      }
    } catch (error: any) {
      handleLoginError(error);
    }
    return null;
  },
  updateIsMonthlyTemplateActivity: async (
    refId: string,
    isMonthly: boolean
  ) => {
    const body = {
      isMonthly: isMonthly,
    };

    try {
      alertService.showLoading();

      const response = await apiClient.patch(
        `/v1/template-activities/${refId}`,
        body
      );

      alertService.closeLoading();

      if (response.status === 200) {
        const result: TemplateActivitiesResponse = response.data;
        return result.results.templateActivities;
      }
    } catch (error: any) {
      handleLoginError(error);
    }

    return null;
  },
  deleteTemplateActivity: async (refId: string) => {
    const body = {
      isDelete: true,
    };

    try {
      const response = await apiClient.patch(
        `/v1/template-activities/${refId}`,
        body
      );

      if (response.status === 200) {
        const result: TemplateActivitiesResponse = response.data;
        return result.results.templateActivities;
      }
    } catch (error: any) {
      handleLoginError(error);
    }

    return null;
  },
  createTemplateActivities: async (
    formData: any
  ): Promise<ResponseTemplateActivities | null> => {
    let result: ResponseTemplateActivities | null = null;

    try {
      alertService.showLoading();

      const response = await apiClient.post(
        "/v1/template-activities",
        formData
      );

      alertService.closeLoading();

      if (response.status === 200 || response.status === 201) {
        result = response.data.results;
      }
    } catch (error: any) {
      handleLoginError(error, formData.year);
      result = null;
    }

    return result;
  },
  monthlyRegenerateTemplateActivities:
    async (): Promise<ResponseTemplateActivities | null> => {
      let result: ResponseTemplateActivities | null = null;

      try {
        alertService.showLoading();
        const response = await apiClient.post(
          "/v1/template-activities/monthly-regenerate"
        );
        alertService.closeLoading();

        if (response.status === 200 || response.status === 201) {
          result = response.data.results;
        }
      } catch (error: any) {
        handleLoginError(error);
        result = null;
      }

      return result;
    },
  editTemplateActivities: async (
    formData: any,
    refId: string
  ): Promise<ResponseTemplateActivities | null> => {
    let result: ResponseTemplateActivities | null = null;

    formData.templateActivityRefId = refId;

    try {
      const response = await apiClient.post(
        "/v1/template-activities",
        formData
      );
      if (response.status === 200 || response.status === 201) {
        result = response.data.results;
      }
    } catch (error: any) {
      handleLoginError(error, formData.year);
      result = null;
    }

    return result;
  },
};

const handleLoginError = async (error: any, year?: number) => {
  const status: number = error.response.status;

  if (status === 500) {
    await alertService.error(`${t("Internal-Server-Error")}`);
    return;
  }

  if (error.response && error.response.data && error.response.data.code) {
    const errorMessage = error.response.data.code;

    switch (errorMessage) {
      case "FORBIDDEN_ACCESS_ERROR":
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
      case "TEMPLATE_ACTIVITY_ALREADY_EXISTS":
        alertService.error(
          t("template_activity_already_exists", {
            year: year,
          })
        );
        break;
      case "VALIDATION_ERROR":
        alertService.error(t("validation-failed"));
        break;
      case "FORBIDDEN_UPDATE_RESTRICTED_FIELDS":
        alertService.info(t("Forbidden-Update-Restricted-Fields"));
        break;
      default:
        alertService.error(t("Cannot-Access-Organization-Error"));
        break;
    }
  } else {
    alertService.error(t("cannot-connect-to-server"));
  }
};

export default TemplateActivitiesService;
