import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridColumnHeaderParams,
  gridFilteredSortedRowIdsSelector,
  GridPagination,
  GridRowId,
  useGridApiRef,
} from "@mui/x-data-grid";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import TableApprovalStatus from "./TableApprovalStatus";
import TablePopper from "../../../components/popper/TablePopper";
import alertService from "../../../components/alert/alertService";
import { AddEmissionContext } from "../../../contexts/AddEmissionContext";
import React, { useEffect, useState } from "react";
import ArrayUtils from "../../../utils/arrayUtils";
import dayjs, { Dayjs } from "dayjs";
import NumberFormatterUtils from "../../../utils/numberFormatterUtils";
import ActivitiesService from "../../../service/activitiesService";
import { Activity, GetActivities } from "../../../models/Activities";
import { ReviewApproval } from "./ReviewApproval";
import { EditEmission } from "./EditEmission";
import { useAuth } from "../../../contexts/UserContext";
import { useForm } from "react-hook-form";
import { tableStyle } from "./EmissionDataTableStyle";
import { QuickSearchToolbar } from "./QuickSearchToolbar";
import "dayjs/locale/th";
import { DROPDOWN_EMISSION_GROUP } from "../../../constants/dropdown";
import { DisabledInput } from "./DisabledInput";
import { QuickUploadFile } from "../../../components/input/QuickUploadFile";

export default function DataTable() {
  const { t, i18n } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { isAdmin } = useAuth();

  const { register, getValues, setValue, setFocus } = useForm();

  const {
    activitiesList,
    setUpActivitiesListData,
    subOrganizationList,
    getActivitiesList,
    getSubOrganizationList,
    getEmissionGroupList,
    getAssetList,
    year,
  } = AddEmissionContext();

  // popper
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openPopper = Boolean(anchorEl);

  const [rowSelect, setRowSelect] = useState<Activity | null>(null);

  const handleOnClickPopper = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setRowSelect(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (event?: any) => {
    setAnchorEl(null);
    if (event.target.id === "review-approval") {
      setOpenReviewApproval(true);
      setOpenReviewApprovalType("approval");
    } else if (event.target.id === "view") {
      setOpenReviewApproval(true);
      setOpenReviewApprovalType("view");
    } else if (event.target.id === "deleted") {
      deleteActivities();
    }
  };

  // table

  const apiRef = useGridApiRef();

  let inputKeyEvent = "";

  const quickUploadFile = async (base64: string, refId: string) => {
    const result = await ActivitiesService.editattachment(
      refId,
      base64,
      year.year()
    );

    if (result) {
      alertService.mixin(t("Upload-Evidence-Success"));
      setUpActivitiesListData(result);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("ID"),
      disableColumnMenu: true,
      minWidth: 80,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value) => `EM-${value}`,
    },
    {
      field: "startDate",
      headerName: t("Date"),
      width: 140,
      disableColumnMenu: true,
      headerAlign: "left",
      type: "date",
      valueFormatter: (value, row) => {
        const start: Dayjs = value;

        const end: Dayjs = row.endDate;

        if (start.isSame(end, "month")) {
          return start.locale(i18n.language).format("MMM YYYY");
        } else {
          return `${start.locale(i18n.language).format("MMM YYYY")} -\n${end
            .locale(i18n.language)
            .format("MMM YYYY")}`;
        }
      },
      renderCell: (params) => {
        return (
          <Typography variant="text1" sx={{ whiteSpace: "pre-line" }}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "suborganizationName",
      headerName: t("Sub-Organizations"),
      headerAlign: "left",
      minWidth: 138,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "siteName",
      headerName: t("Site"),
      headerAlign: "left",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "assetName",
      headerName: t("Asset"),
      minWidth: 112,
      headerAlign: "left",
      // flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "emissionGroup",
      headerName: t("Emission-Group"),
      width: 134,
      headerAlign: "left",
      disableColumnMenu: true,
      valueFormatter: (value) => {
        const item = DROPDOWN_EMISSION_GROUP.find(
          (e) => e.emissionGroup === value
        );

        return item ? t(item.groupEn) : t(value);
      },
    },
    {
      field: "activityAttributeEN",
      headerName: t("Emission-Source"),
      minWidth: 156,
      flex: 1,
      headerAlign: "left",
      disableColumnMenu: true,
      valueFormatter: (value, row) =>
        i18n.language === "en"
          ? row.activityAttributeEN
          : row.activityAttributeTh,
    },
    {
      field: "amount",
      headerName: t("Amount"),
      headerAlign: "left",
      minWidth: 130,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      type: "number",
      getApplyQuickFilterFn: undefined,
      renderCell: (params) => {
        const originalRow = params.row as Activity;

        const inputId = `input-amount-${params.id}`;

        try {
          setValue(inputId, params.value ?? 0);
        } catch (error) {
          setValue(inputId, 0);
        }

        const item = DROPDOWN_EMISSION_GROUP.find(
          (e) => e.emissionGroup === params.row.emissionGroup
        );

        const canQuickUpdateAmount = item?.canQuickUpdateAmount ?? true;

        const disabled: boolean =
          originalRow.status === "approved" || !canQuickUpdateAmount
            ? true
            : false;

        return !canQuickUpdateAmount ? (
          <DisabledInput inputName={inputId} value={params.value} />
        ) : (
          <TextField
            autoComplete="off"
            {...register(inputId, {
              required: true,
              async onBlur(event) {
                await handleUpdateAmount(originalRow);

                if (inputKeyEvent !== "") {
                  autoFoucs(originalRow.id);
                }
              },
            })}
            key={inputId}
            id={inputId}
            defaultValue={params.value}
            disabled={disabled}
            onFocus={(event) => {
              // คลุมดำทั้งหมด
              setTimeout(() => {
                event.target.select();
              }, 50);
            }}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onKeyUp={async (event: React.KeyboardEvent) => {
              switch (event.key) {
                case "Enter":
                  inputKeyEvent = event.key;
                  (document.activeElement as HTMLElement).blur();
                  break;
                case "ArrowUp":
                  inputKeyEvent = event.key;
                  (document.activeElement as HTMLElement).blur();
                  break;
                case "ArrowDown":
                  inputKeyEvent = event.key;
                  (document.activeElement as HTMLElement).blur();
                  break;
                default:
                  break;
              }
            }}
            sx={{
              "&.Mui-selected": {
                outline: "none !important",
              },
            }}
          />
        );
      },
    },
    {
      field: "unit",
      headerName: t("Unit"),
      sortable: false,
      headerAlign: "left",
      minWidth: 50,
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "carbonEquivalentValue",
      sortable: false,
      minWidth: 150,
      type: "number",
      headerAlign: "left",
      flex: 1,
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      renderHeader: () => (
        <Box
          sx={{
            height: "20.02px",
          }}
        >
          {t("Emission")} (CO<sub>2</sub>e)
        </Box>
      ),
      renderCell: (params) => {
        let result: number = 0;

        try {
          result =
            params.row.scopes.s1 + params.row.scopes.s2 + params.row.scopes.s0;

          if (process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true") {
            result =
              params.row.scopes.s1 +
              params.row.scopes.s2 +
              params.row.scopes.s0 +
              params.row.scopes.s3;
          }
        } catch (error) {
          result = 0;
        }

        return (
          <Box alignItems={"right"}>
            <TablePopper
              text={NumberFormatterUtils.numberFormat(result)}
              scope={params.row.scopes}
              unit={"kg"}
            />
          </Box>
        );
      },
    },
    {
      field: "attachment",
      headerName: t("Files"),
      sortable: false,
      width: 80,
      align: "center",
      headerAlign: "center",
      getApplyQuickFilterFn: undefined,
      disableColumnMenu: true,
      valueFormatter: (params: string) => {
        try {
          const fileName = params.split(":")[0] ?? "";

          return fileName;
        } catch (error) {
          return "";
        }
      },
      renderCell: (params) => {
        const value = params.value ?? "";
        return (
          <QuickUploadFile
            value={value}
            id={params.row.id}
            quickUploadFile={(event: any) =>
              quickUploadFile(event, params.row._id)
            }
          />
        );
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      disableColumnMenu: true,
      width: 100,
      headerAlign: "left",
      getApplyQuickFilterFn: undefined,
      renderCell: (params) => {
        return (
          <TableApprovalStatus
            status={params.value}
            by={
              params.value === "Approval"
                ? params.row.approvalBy
                : params.row.createdBy
            }
          />
        );
      },
    },
    {
      field: "",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      type: "actions",
      width: 70,
      getApplyQuickFilterFn: undefined,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <img
                style={{
                  opacity: params.row.status === "approved" ? 0.3 : 1,
                }}
                src="/img/edit-2.svg"
                alt="icon-edit"
              />
            }
            label="edit"
            disabled={params.row.status === "approved" || !isAdmin}
            onClick={() => {
              editEmission(params.row);
            }}
          />,
          <GridActionsCellItem
            key={`${params.row.id}-more`}
            icon={<img src="/img/more.svg" alt="icon-more" />}
            label="more"
            id="basic-button"
            aria-controls={openPopper ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openPopper ? "true" : undefined}
            onClick={(event) => handleOnClickPopper(event, params.row)}
          />,
        ];
      },
    },
  ];

  const [rows, setRows] = useState<any[]>([]);

  const editActivitiesAmount = async (updatedRow: Activity) => {
    const isConfirmed = await alertService.confirm(
      t("Edit-Emission"),
      <Trans
        t={t}
        i18nKey="Edit-Emission-Model-text"
        values={{ id: updatedRow.id }}
        components={{
          span: <span style={{ color: "var(--red)" }} />,
        }}
      />,
      t("Save")
    );

    if (isConfirmed) {
      const editResult: GetActivities | null =
        await ActivitiesService.editActivitiesAmount(
          updatedRow._id,
          updatedRow.amount,
          year.year()
        );

      if (editResult === null) {
        return false;
      }

      setUpActivitiesListData(editResult);

      alertService.mixin(t("edit-emission-amount-successfully"));

      return true;
    }

    return false;
  };

  const deleteActivities = async () => {
    if (rowSelect) {
      const isConfirmed = await alertService.confirm(
        t("Are-You-Sure-Want-To-Delete"),
        <Trans
          t={t}
          i18nKey="delete-emission-model-text"
          values={{ id: rowSelect.id }}
          components={{
            span: <span style={{ color: "var(--red)" }} />,
          }}
        />,
        t("Delete"),
        "var(--red)"
      );

      if (isConfirmed) {
        const result = await ActivitiesService.deleteActivities(
          rowSelect._id,
          year.year()
        );

        alertService.mixin(t("delete-emission-successfully"));

        if (result) {
          setUpActivitiesListData(result);
        }
      }
    }
  };

  const handleUpdateAmount = async (originalRow: Activity) => {
    await new Promise((resolve) => setTimeout(resolve, 50));

    const inputName = `input-amount-${originalRow.id}`;

    const newAmount: string = getValues(inputName);

    let newAmountNumber: number = Number(newAmount.replaceAll(",", ""));

    if (newAmountNumber === originalRow.amount) {
      setValue(inputName, originalRow.amount);
      return;
    }

    if (Number.isNaN(newAmountNumber)) {
      await alertService.error(t("only-numbers-are-allowed"));
      inputKeyEvent = "";
      setTimeout(() => {
        setValue(inputName, originalRow.amount);
        setFocus(inputName);
      }, 50);

      return;
    }

    if (newAmountNumber < 0) {
      await alertService.error(t("can't_be_less_than_0"));
      inputKeyEvent = "";
      setTimeout(() => {
        setValue(inputName, originalRow.amount);
        setFocus(inputName);
      }, 50);

      return;
    }

    const updatedRow = { ...originalRow };

    updatedRow.amount = newAmountNumber;

    const result = await editActivitiesAmount(updatedRow);

    if (result) {
      // autoFoucs(originalRow.id);

      return true;
    } else {
      setValue(inputName, originalRow.amount);
    }
  };

  const autoFoucs = (currentId: number) => {
    const allRow = gridFilteredSortedRowIdsSelector(apiRef);

    const currentIndex = allRow.findIndex((e) => e === currentId);

    let nextIndexId: GridRowId = 0;

    switch (inputKeyEvent) {
      case "Enter":
        nextIndexId = currentIndex + 1;
        break;
      case "ArrowUp":
        nextIndexId = currentIndex - 1;
        break;
      case "ArrowDown":
        nextIndexId = currentIndex + 1;
        break;
      default:
        nextIndexId = -1;
        break;
    }

    inputKeyEvent = "";

    if (nextIndexId !== -1) {
      try {
        nextIndexId = allRow[nextIndexId];

        setTimeout(() => {
          setFocus(`input-amount-${nextIndexId}`);
        }, 50);
      } catch (error) {}
    }
  };

  const prepareDataForTable = () => {
    if (!ArrayUtils.arraysEqual(rows, activitiesList)) {
      let temp: Activity[] = [];

      let itemTemp: Activity;

      activitiesList.forEach((i) => {
        itemTemp = i;

        itemTemp.startDate = dayjs(itemTemp.startDate);

        itemTemp.endDate = dayjs(itemTemp.endDate);

        temp.push(itemTemp);
      });

      setRows(temp);
    }
  };

  const [openReviewApproval, setOpenReviewApproval] = useState(false);

  const [openReviewApprovalType, setOpenReviewApprovalType] = useState("");

  const editEmission = (row: Activity) => {
    setRowSelect(row);
    setOpenEdit(true);
  };

  const [openEdit, setOpenEdit] = useState(false);

  const CustomPagination = () => {
    return (
      <GridPagination
        showFirstButton
        showLastButton
        labelRowsPerPage={t("rows-per-page")}
      />
    );
  };

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getActivitiesList();
      getSubOrganizationList();
      getEmissionGroupList();
      getAssetList();
    }
    prepareDataForTable();
  }, [activitiesList]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection={"column"}
      style={{ width: "100%" }}
    >
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openPopper}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          disabled={rowSelect?.status.toLowerCase() !== "pending"}
          onClick={handleClose}
          id="review-approval"
        >
          {t("Review-Approval")}
        </MenuItem>
        <MenuItem onClick={handleClose} id="view">
          {t("View")}
        </MenuItem>
        {/* <MenuItem onClick={handleClose} disabled>
          {t("Duplicate")}
        </MenuItem> */}
        <Divider />
        <MenuItem
          id="deleted"
          onClick={handleClose}
          disabled={rowSelect?.status.toLowerCase() === "approved"}
          sx={{
            color: "var(--red)",
          }}
        >
          {t("Delete")}
        </MenuItem>
      </Menu>

      {rowSelect ? (
        <ReviewApproval
          data={rowSelect}
          subOrganizationList={subOrganizationList}
          open={openReviewApproval}
          onClose={() => {
            setOpenReviewApproval(false);
            setRowSelect(null);
          }}
          type={openReviewApprovalType}
        />
      ) : null}

      {rowSelect ? (
        <EditEmission
          data={rowSelect}
          open={openEdit}
          onClose={() => {
            setOpenEdit(false);
            setRowSelect(null);
          }}
        />
      ) : null}

      <Box
        width={"100%"}
        height={"100%"}
        minHeight={{ xs: "660px", sm: "auto" }}
        maxHeight={{
          xs: "auto",
          md: window.innerHeight - 53 + 40,
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          rowHeight={60}
          disableRowSelectionOnClick
          apiRef={apiRef}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          onCellKeyDown={(params, event, details) => {
            if (params.field === "amount") {
              event.stopPropagation();
              return;
            }
          }}
          slots={{
            toolbar: QuickSearchToolbar,
            pagination: CustomPagination,
          }}
          localeText={{
            toolbarExport: t("Export"),
            toolbarQuickFilterPlaceholder: `${t("search")}...`,
            noRowsLabel: `${t("No-Data-In-Year", { year: year.year() })}`,
            noResultsOverlayLabel: t("noResultsOverlayLabel"),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                variant: "outlined",
              },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          sx={tableStyle()}
        />
      </Box>
    </Box>
  );
}
