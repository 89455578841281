import { t } from "i18next";
import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import {
  EconomicIndicator,
  ResponseCreateEconomicIndicators,
} from "../models/dto/response/ResponseCreateEconomicIndicators";
import {
  EcoEfficiency,
  EcoEfficiencyResponse,
} from "../models/dto/response/ResponseEcoEfficiency";
import { BaseYearResponse } from "../models/BaseYear";
import { EnvironmentalImpactTypesResponse } from "../models/EnvironmentalImpactTypes";

export const EcoEfficiencyService = {
  recalculateEcoEfficiency: async (
    refId: string
  ): Promise<EcoEfficiency[] | null> => {
    let result: EcoEfficiency[] | null = null;
    try {
      alertService.showLoading();
      const response = await apiClient.patch(
        `/v1/eco-efficiency/${refId}/recalculate`
      );
      alertService.closeLoading();

      const temp: EcoEfficiencyResponse = response.data;

      result = temp.results.ecoEfficiencies;
    } catch (error: any) {
      handleError(error);
      result = null;
    }

    return result;
  },
  updateEcoEfficiency: async (
    refId: string,
    form: any
  ): Promise<EcoEfficiency[] | null> => {
    let result: EcoEfficiency[] | null = null;
    try {
      alertService.showLoading();
      const response = await apiClient.patch(
        `/v1/eco-efficiency/${refId}`,
        form
      );
      alertService.closeLoading();

      const temp: EcoEfficiencyResponse = response.data;

      result = temp.results.ecoEfficiencies;
    } catch (error: any) {
      handleError(error);
      result = null;
    }

    return result;
  },
  deleteEcoEfficiency: async (
    refId: string
  ): Promise<EcoEfficiency[] | null> => {
    let result: EcoEfficiency[] | null = null;
    try {
      alertService.showLoading();
      const response = await apiClient.delete(`/v1/eco-efficiency/${refId}`);
      alertService.closeLoading();

      const temp: EcoEfficiencyResponse = response.data;

      result = temp.results.ecoEfficiencies;
    } catch (error: any) {
      handleError(error);
      result = null;
    }

    return result;
  },
  createEcoEfficiency: async (form: any): Promise<EcoEfficiency[] | null> => {
    let result: EcoEfficiency[] | null = null;
    try {
      alertService.showLoading();
      const response = await apiClient.post("/v1/eco-efficiency", form);
      alertService.closeLoading();

      const temp: EcoEfficiencyResponse = response.data;

      result = temp.results.ecoEfficiencies;
    } catch (error: any) {
      handleError(error);
      result = null;
    }

    return result;
  },
  getEnvironmentalImpactTypes: async (): Promise<string[] | null> => {
    let result: any | null = null;
    try {
      alertService.showLoading();
      const response = await apiClient.get(
        "/v1/environmental-impact-types/options"
      );
      alertService.closeLoading();

      const temp: EnvironmentalImpactTypesResponse = response.data;

      result = temp.results.type;
    } catch (error: any) {
      handleError(error);
      result = null;
    }

    return result;
  },
  getBaseYear: async (body: any, refId?: string): Promise<number[] | null> => {
    // ถ้า edit ใส่ refId
    let result: any | null = null;

    try {
      alertService.showLoading();
      const response = await apiClient.post(
        refId ? `/v1/base-years/options/${refId}` : "/v1/base-years/options",
        body
      );
      alertService.closeLoading();

      const temp: BaseYearResponse = response.data;

      result = temp.results.years;
    } catch (error: any) {
      alertService.closeLoading();

      // handleError(error);
      result = null;
    }

    return result;
  },
  getEcoEfficiency: async (): Promise<EcoEfficiency[] | null> => {
    let result: any | null = null;

    try {
      alertService.showLoading();
      const response = await apiClient.get("/v1/eco-efficiency");
      alertService.closeLoading();

      const temp: EcoEfficiencyResponse = response.data;

      result = temp.results.ecoEfficiencies;
    } catch (error: any) {
      handleError(error);
      result = null;
    }

    return result;
  },

  getEconomicIndicators: async (): Promise<EconomicIndicator[] | null> => {
    let result: any | null = null;

    try {
      alertService.showLoading();
      const response = await apiClient.get("/v1/economic-indicators");
      alertService.closeLoading();

      const temp: ResponseCreateEconomicIndicators = response.data;

      result = temp.results.economicIndicators;
    } catch (error: any) {
      handleError(error);

      result = null;
    }

    return result;
  },
  createEconomicIndicators: async (
    formData: any
  ): Promise<EconomicIndicator[] | null> => {
    let result: any | null = null;

    try {
      alertService.showLoading();
      const response = await apiClient.post(
        "/v1/economic-indicators",
        formData
      );
      alertService.closeLoading();

      const temp: ResponseCreateEconomicIndicators = response.data;

      result = temp.results.economicIndicators;
    } catch (error: any) {
      handleError(error);

      result = null;
    }

    return result;
  },
  editEconomicIndicators: async (
    refId: string,
    formData: any
  ): Promise<EconomicIndicator[] | null> => {
    let result: any | null = null;

    try {
      alertService.showLoading();
      const response = await apiClient.patch(
        `/v1/economic-indicators/${refId}`,
        formData
      );
      alertService.closeLoading();

      const temp: ResponseCreateEconomicIndicators = response.data;

      result = temp.results.economicIndicators;
    } catch (error: any) {
      handleError(error);

      result = null;
    }

    return result;
  },
  enableDisableEconomicIndicators: async (
    refId: string
  ): Promise<EconomicIndicator[] | null> => {
    let result: any | null = null;

    try {
      const response = await apiClient.patch(
        `/v1/economic-indicators/${refId}/enable-disable`
      );

      const temp: ResponseCreateEconomicIndicators = response.data;

      result = temp.results.economicIndicators;
    } catch (error: any) {
      handleError(error);

      result = null;
    }

    return result;
  },
};

const handleError = async (error: any) => {
  const status: number = error.response.status;

  if (status === 500) {
    await alertService.error(`${t("Internal-Server-Error")}`);
    return;
  }

  if (error.response && error.response.data && error.response.data.code) {
    const errorCode = error.response.data.code;

    const errorMessage: string = error.response.data.message.body ?? "";

    switch (errorCode) {
      case "VALIDATION_ERROR":
        if (errorMessage && errorMessage.includes("Duplicate")) {
          alertService.error(t("Component-Name-Duplicated"));
        } else {
          alertService.error(t("validation-failed"));
        }
        break;
      case "FORBIDDEN_ACCESS_ERROR":
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
      case "ECONOMIC_INDICATOR_ALREADY_EXISTS":
        alertService.error(t("Economic-Indicator-Already-Exists"));
        break;
      case "RESOURCE_ALREADY_IN_USE":
        alertService.error(t("Resource-Already-In-Use"));
        break;
      case "ENVIRONMENTAL_IMPACT_TYPES_RETRIEVE_FAILED":
        alertService.error(t("Environmental-Impact-Types-Retrieve-Failed"));
        break;
      case "BASE_YEAR_OPTIONS_RETRIEVE_FAILED":
        alertService.error(t("Base-Year-Options-Retrieve-Failed"));
        break;
      case "ECO_EFFICIENCY_ALREADY_EXISTS":
        alertService.error(t("Eco-Efficiency-Already-Exists"));
        break;
      case "PLAN_EXPIRED":
        alertService.info(t("PLAN_EXPIRED"), t("Please-Upgrade-Package"));
        break;
      case "FORBIDDEN_ACTION_ERROR":
        alertService.error(t("Forbidden-Action-Error"));
        break;
      default:
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
    }
  } else {
    alertService.error(t("cannot-connect-to-server"));
  }
};
