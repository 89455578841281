import {
  Box,
  Button,
  Hidden,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import { useForm } from "react-hook-form";
import alertService from "../../components/alert/alertService";
import { useAuth } from "../../contexts/UserContext";
import { SubOrganizations } from "../../models/SubOrganization";
import SubOrganizationsService from "../../service/subOrganizationsService";
import { AutoLocation } from "./AutoLocation";

type SubOrganizationProfileMode = "add" | "edit";

interface Props {
  mode: SubOrganizationProfileMode;
}

interface FormValues {
  name: string;
  city: string;
  postalCode: string;
  country: string;
  address: string;
  district: string;
  subDistrict: string;
}

const SubOrganizationProfile = (props: Props) => {
  const { t } = useTranslation(["common", "organization", "input"], {
    nsMode: "fallback",
  });

  const context = useContext(OrganizationContext);

  const { isAdmin } = useAuth();

  if (!context) {
    throw Error();
  }

  const subOrganization = context.subOrganizations?.find((item) => {
    return item.id === context.selectedSubOrganization;
  });

  const onSubmit = async (values: FormValues) => {
    const oldAddress: string = subOrganization?.location?.address ?? "";

    const oldDistrict: string = subOrganization?.location?.district ?? "";

    if (
      values.name.trim() === subOrganization?.name &&
      values.city.trim() === subOrganization?.location?.city &&
      values.postalCode.trim() === subOrganization?.location?.postalCode &&
      values.country.trim() === subOrganization?.location?.country &&
      values.address.trim() === oldAddress &&
      values.district.trim() === oldDistrict &&
      values.subDistrict.trim() === subOrganization?.location?.subdistrict
    ) {
      return;
    }

    try {
      const body: any = {
        _id: subOrganization?.refId,
        name: values.name,
        location: {
          city: values.city,
          postalCode: values.postalCode,
          country: values.country,
          address: values.address ?? "",
          district: values.district ?? "",
          subdistrict: values.subDistrict ?? "",
        },
      };

      if (props.mode === "add") {
        delete body._id;
      }

      if (values.name.trim() === subOrganization?.name.trim()) {
        delete body.name;
      }

      if (body.location.address.trim() === "") {
        delete body.location.address;
      }

      if (body.location.district.trim() === "") {
        delete body.location.district;
      }

      if (body.location.subdistrict.trim() === "") {
        delete body.location.subdistrict;
      }

      const result: SubOrganizations[] | null =
        await SubOrganizationsService.editSubOrganizations(body);

      if (result) {
        alertService.mixin(t("Successfully"));
        context.setOpenModal(false);
        context.setSubOrganizations(result);
      }
    } catch (error) {
      alertService.error(t("validation-failed"));
    }
  };

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: "all",
    reValidateMode: "onChange",
  });

  const checkKeyDown = (event: any) => {
    if (event.key === "Enter") event.preventDefault();
  };

  useEffect(() => {
    if (props.mode === "edit") {
      clearErrors();

      if (subOrganization) {
        setValue("name", subOrganization.name);

        setValue("address", subOrganization.location?.address ?? "");

        setValue("country", subOrganization.location?.country ?? "");

        setValue("city", subOrganization.location?.city ?? "");

        setValue("district", subOrganization.location?.district ?? "");

        setValue("subDistrict", subOrganization.location?.subdistrict ?? "");

        setValue("postalCode", subOrganization.location?.postalCode ?? "");
      }
    }
  }, [subOrganization]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
          display={"flex"}
          flexDirection={"column"}
          gap={"40px"}
        >
          <Box
            flex={1}
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <Grid2
              container
              rowGap={"20px"}
              columnSpacing={"40px"}
              sx={{
                width: "100%",
              }}
            >
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Sub-Organization-Name-Display")}
                  info={t("Sub-Organization-Name-Display")}
                  name="name"
                  placeholder={t("Sub-Organization-Name-Display")}
                  control={control}
                  defaultValue={subOrganization?.name ?? ""}
                  required
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Sub-Organization-Name-Display"),
                    }),
                    pattern: {
                      value: /^[^,]*$/,
                      message: t("Name-Fails-To-Match-Pattern"),
                    },
                    validate: (text: string) => {
                      const index = context?.subOrganizations?.findIndex(
                        (sub) =>
                          sub.name.trim().toLowerCase() ===
                          text.trim().toLowerCase()
                      );

                      return index !== undefined &&
                        index >= 0 &&
                        subOrganization?.name !== text
                        ? t("SUBORGANIZATION_ALREADY_EXISTS")
                        : true;
                    },
                  }}
                  disabled={!isAdmin()}
                />
              </Grid2>
              {props.mode === "edit" ? (
                <Grid2 xs={12} md={6}>
                  <Stack gap={"8px"}>
                    <Typography variant="h3">
                      {t("sub-organizations-id")}
                    </Typography>
                    <Paper
                      elevation={0}
                      variant="outlined"
                      sx={{
                        height: "36px",
                        boxSizing: "border-box",
                        flexGrow: 1,
                        p: 0,
                        backgroundColor: "var(--gray5)",
                      }}
                    >
                      <Stack direction={"row"} height={"100%"}>
                        <Typography
                          alignContent={"center"}
                          sx={{ flexGrow: 1, px: "12px" }}
                        >
                          {subOrganization?.id}
                        </Typography>
                        <IconButton
                          color="primary"
                          sx={{ p: "10px" }}
                          aria-label="directions"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              subOrganization?.id.toString() ?? ""
                            )
                          }
                        >
                          <img src="/img/copy.svg" alt="copy" />
                        </IconButton>
                      </Stack>
                    </Paper>
                  </Stack>
                </Grid2>
              ) : (
                <Hidden mdDown>
                  <Grid2 xs={12} md={6}></Grid2>
                </Hidden>
              )}

              <AutoLocation
                control={control}
                setValue={setValue}
                watch={watch}
                disabled={!isAdmin()}
              />

              <Grid2 xs={12}>
                {isAdmin() && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!isValid}
                  >
                    {props.mode === "edit" ? t("Save") : t("Add")}
                  </Button>
                )}
              </Grid2>
            </Grid2>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default SubOrganizationProfile;
