import {
  Box,
  Button,
  FormControl,
  Hidden,
  TextField,
  Typography,
} from "@mui/material";
import { EcoEfficiencyContext } from "../../contexts/EcoEfficiencyContext";
import { OrganizationBreadcrumbs } from "../emission/OrganizationBreadcrumbs";
import CustomModal from "../../components/modal/CustomModal";
import { useAuth } from "../../contexts/UserContext";
import { CustomAutoComplete } from "../../components/input/CustomAutoComplete";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LabelInput from "../../components/input/LabelInput";
import dayjs, { Dayjs } from "dayjs";
import CustomDatePicker from "../../components/input/CustomDatePicker";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { useContext, useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Component } from "../../models/dto/response/ResponseCreateEconomicIndicators";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { tableStyle } from "../emission/data-table/EmissionDataTableStyle";
import { NUMBER_REGEX } from "../../constants/form";
import { EcoEfficiencyService } from "../../service/ecoEfficiencyService";
import alertService from "../../components/alert/alertService";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import { useNavigate } from "react-router-dom";
import { MenuData } from "../../components/input/CustomSelect";

type AddEcoEfficiencyFormValue = {
  startDate: Dayjs;
  endDate: Dayjs;
  indicatorRef: string;
  indicatorValues: [];
  suborganizationRef: string;
  environmentalImpactType: string;
  baseYear: string;
  remark: string;
  [key: string]: any;
};

export const AddEcoEfficiency = () => {
  const { isAdmin } = useAuth();

  const navigate = useNavigate();

  const { t } = useTranslation(["common", "input", "economic"], {
    nsMode: "fallback",
  });

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
    unregister,
    register,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<AddEcoEfficiencyFormValue>({
    reValidateMode: "onChange",
    mode: "all",
  });

  const startDate = watch("startDate");

  const endDate = watch("endDate");

  const suborganizationRef = watch("suborganizationRef");

  const indicatorRef = watch("indicatorRef");

  const { organization } = useAuth();

  const {
    modalIsOpen,
    setModalIsOpen,
    baseYearOptions,
    environmentImpactTypeOptions,
    economicIndicators,
    getBaseYear,
    setBaseYearOptions,
    setEcoEfficiency,
    editData,
    setEditData,
    masterEconomicIndicators,
  } = EcoEfficiencyContext();

  const organizationContext = useContext(OrganizationContext);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      setModalIsOpen(true);
      buildOptionEconomicIndicators();
    } else {
      reset();
      setRows([]);
      setEditData(undefined);
      setBaseYearIsDisabled(true);
      setOptionEconomicIndicators([]);
    }
  }, [modalIsOpen]);

  const prepareFormData = (data: AddEcoEfficiencyFormValue) => {
    const item = masterEconomicIndicators.find((e) => e._id === indicatorRef);
    let form: any = {
      startDate: data.startDate.format("YYYY-MM-DD"),
      endDate: data.endDate.format("YYYY-MM-DD"),
      // indicatorRef: data.indicatorRef,
      indicatorValues: item?.components.map((m) => ({
        name: m.nameEn,
        amount: getValues(m.nameEn),
      })),
      suborganizationRef: data.suborganizationRef,
      environmentalImpactType: data.environmentalImpactType,
      baseYear: item && item.isRequireBaseYear ? data.baseYear : "",
    };

    if (
      !editData ||
      (editData && editData.indicatorRef._id.toString() !== data.indicatorRef)
    ) {
      // ตอน edit check ว่า indicator ref เปลี่ยนมั้ย ถ้าเปลี่ยนส่ง ไม่เปลี่ยนไม่ต้องส่ง
      form.indicatorRef = data.indicatorRef;
    }

    if (data.remark !== "" && data.remark) {
      form.remark = data.remark;
    }

    return form;
  };

  const onSubmit = async (data: AddEcoEfficiencyFormValue) => {
    const form = prepareFormData(data);
    const action = editData
      ? EcoEfficiencyService.updateEcoEfficiency(editData._id.toString(), form)
      : EcoEfficiencyService.createEcoEfficiency(form);

    const result = await action;
    if (result) {
      alertService.mixin(
        t(
          editData
            ? "EcoEfficiency-Edit-Successfully"
            : "EcoEfficiency-Created-Successfully"
        )
      );
      setEcoEfficiency(result);
      closeModal();
    } else if (editData) {
      closeModal();
    }
  };

  const scrollToError = (errors: Record<string, any>) => {
    try {
      const errorFields = Object.keys(errors);
      const errorElement = document.getElementById(errorFields[0]);
      errorElement?.scrollIntoView({ behavior: "smooth", block: "start" });
    } catch (error) {
      console.error("Error scrolling to field", error);
    }
  };

  const removeField = () => {
    componentsFields.forEach((e) => {
      setValue(e.nameEn, "");
      unregister(e.nameEn);
    });
  };

  const [componentsFields, setComponentsFields] = useState<Component[]>([]);

  const [optionEconomicIndicators, setOptionEconomicIndicators] = useState<
    MenuData[]
  >([]);

  // ถ้าองค์กรย่อยเปลี่ยน ค่าของ indicator เปลี่ยนด้วย
  useEffect(() => {
    if (
      suborganizationRef !== "" &&
      suborganizationRef !== null &&
      indicatorRef !== "" &&
      indicatorRef !== null &&
      startDate &&
      endDate
    ) {
      const item = masterEconomicIndicators.find((e) => e._id === indicatorRef);

      if (item) {
        setRows(
          item?.components.map((i, index) => ({
            id: index,
            nameEn: i.nameEn,
            nameTh: i.nameTh,
            unit: i.unit,
          })) ?? []
        );

        setBaseYearIsDisabled(!item.isRequireBaseYear);

        if (item.isRequireBaseYear) {
          getBaseYear(
            {
              suborganizationRef: suborganizationRef,
              indicatorRef: indicatorRef,
              startDate: startDate.format("YYYY-MM-DD"),
              endDate: endDate.format("YYYY-MM-DD"),
            },
            editData ? item._id : undefined
          );
        } else {
          setValue("baseYear", "");
        }
      }
    }
  }, [suborganizationRef, indicatorRef, startDate, endDate]);

  useEffect(() => {
    try {
      if (baseYearOptions.length === 1) {
        setValue("baseYear", baseYearOptions[0].toString());
      } else if (baseYearOptions.length > 1) {
        editData
          ? setValue("baseYear", editData.baseYear.toString())
          : setValue("baseYear", baseYearOptions[0].toString());

        setBaseYearIsDisabled(false);
      } else {
        setBaseYearOptions([
          startDate.month() >= 5 ? startDate.year() : endDate.year(),
        ]);
      }
    } catch (error) {}
  }, [baseYearOptions]);

  useEffect(() => {
    if (indicatorRef === "add") {
      // ไปหน้าเพิ่ม indicator
      navigate("/setting/economic-indicator");
    }

    const item = masterEconomicIndicators.find((e) => e._id === indicatorRef);

    if (
      item &&
      JSON.stringify(item.components) !== JSON.stringify(componentsFields)
    ) {
      removeField();
      setComponentsFields(item.components);
    }
  }, [indicatorRef, economicIndicators, componentsFields]);

  useEffect(() => {
    if (editData) {
      setValue(
        "suborganizationRef",
        editData.suborganizationRef._id.toString()
      );
      setValue("startDate", dayjs(editData.startDate.substring(0, 10)));

      setValue("endDate", dayjs(editData.endDate.substring(0, 10)));

      setValue("indicatorRef", `${editData.indicatorRef._id}`);

      setValue("environmentalImpactType", editData.environmentalImpactType);

      setValue(
        "baseYear",
        editData.baseYear ? editData.baseYear.toString() : ""
      );

      setValue("remark", editData.remark ?? "");

      editData.indicatorValues.forEach((e) => {
        setValue(e.name, e.amount);
      });

      setRows(
        editData.indicatorRef.components.map((i, index) => ({
          id: index,
          nameEn: i.nameEn,
          nameTh: i.nameTh,
          unit: i.unit,
        })) ?? []
      );
    }
  }, [editData]);

  const [baseYearIsDisabled, setBaseYearIsDisabled] = useState<boolean>(true);

  const [rows, setRows] = useState<any[]>([]);

  const columns: GridColDef<Component>[] = [
    {
      field: "nameEn",
      headerName: `${t("Component")} (${t("English")})`,
      disableColumnMenu: true,
      headerAlign: "left",
      align: "left",
      flex: 1,
      minWidth: 180,
      editable: false,
      sortable: false,
    },
    {
      field: "nameTh",
      headerName: `${t("Component")} (${t("Thai")})`,
      disableColumnMenu: true,
      headerAlign: "left",
      align: "left",
      flex: 1,
      minWidth: 180,
      editable: false,
      sortable: false,
    },
    {
      field: "",
      headerName: `${t("Amount-of-Product-Value-Indicator")}`,
      disableColumnMenu: true,
      headerAlign: "left",
      align: "left",
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              ".MuiFormHelperText-root": {
                position: "absolute !important",
                bottom: "-22px !important",
              },
            }}
          >
            <TextField
              {...register(params.row.nameEn, {
                required: t("form-reqired-error", {
                  param: t("Amount-of-Product-Value-Indicator"),
                }),
                pattern: {
                  value: NUMBER_REGEX,
                  message: t("only-numbers-are-allowed"),
                },
                validate: (value) => {
                  return value > 0 || t("The-Value-Must-Be-Greater-Than-Zero");
                },
              })}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              helperText={
                errors[params.row.nameEn] &&
                errors[params.row.nameEn]?.message?.toString()
              }
              error={errors[params.row.nameEn] && true}
            />
          </Box>
        );
      },
    },
    {
      field: "unit",
      headerName: `${t("Unit")} (${t("English")})`,
      disableColumnMenu: true,
      headerAlign: "left",
      align: "left",
      minWidth: 180,
      editable: false,
      sortable: false,
    },
  ];

  const buildOptionEconomicIndicators = () => {
    try {
      clearErrors("indicatorRef");

      let temp: MenuData[] = [];
      // สร้าง option

      if (economicIndicators.length > 0) {
        temp = economicIndicators.map((e) => ({
          nameEN: e.nameEn,
          nameTH: e.nameTh,
          value: e._id,
        }));
      } else if (economicIndicators.length === 0 && isAdmin()) {
        // เพิ่มตัวเลือก Add-Economic-Indicator
        temp.push({
          value: "add",
          nameEN: t(`${"Add-Economic-Indicator"}`),
          nameTH: t(`${"Add-Economic-Indicator"}`),
        });
      } else if (economicIndicators.length === 0 && !isAdmin()) {
        setError("indicatorRef", {
          message: t("No-Economic-Indicator"),
        });
      }

      if (editData && editData.indicatorRef.isDisable) {
        clearErrors("indicatorRef");

        temp.push({
          nameEN: editData.indicatorRef.nameEn,
          nameTH: editData.indicatorRef.nameTh,
          value: editData.indicatorRef._id,
          disabled: true,
        });
      }

      setOptionEconomicIndicators(temp);
    } catch (error) {}
  };

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        startIcon={<img src="/img/add-circle.svg" alt="add-circle" />}
        onClick={openModal}
      >
        {t("Add-Eco-Efficiency")}
      </Button>
      <CustomModal
        header={
          <Typography variant="h1">
            {editData ? t("Edit-Eco-Efficiency") : t("Add-Eco-Efficiency")}
          </Typography>
        }
        open={modalIsOpen}
        onClose={closeModal}
        body={
          <>
            <Box marginY={"20px"}>
              <OrganizationBreadcrumbs
                organization={organization?.name ?? ""}
                location={""}
                subOrganizations={""}
              />
            </Box>

            <Box>
              <form onSubmit={handleSubmit(onSubmit, scrollToError)} id="form">
                <Grid2
                  container
                  columnSpacing={"40px"}
                  rowSpacing={"20px"}
                  disableEqualOverflow
                  sx={{
                    padding: "24px 0px",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <Grid2 xs={12}>
                    <LabelInput
                      label={t("Eco-Efficiency")}
                      info={t("Eco-Efficiency-description")}
                      required
                      showRequired={false}
                    />
                  </Grid2>

                  <Grid2 xs={12}>
                    <CustomAutoComplete
                      required
                      rules={{
                        required: t("Please-Select-Organization"),
                      }}
                      name="suborganizationRef"
                      control={control}
                      label={t("Sub-Organizations")}
                      info={t("Sub-Organizations")}
                      placeholder={t("Select-Param", {
                        param: t("Sub-Organizations"),
                      })}
                      listMenuItem={
                        organizationContext &&
                        organizationContext.subOrganizations
                          ? organizationContext?.subOrganizations?.map(
                              (sub) => ({
                                nameEN: sub.name,
                                nameTH: sub.name,
                                value: sub.refId,
                              })
                            )
                          : []
                      }
                      defaultValue={""}
                    />
                  </Grid2>

                  <Grid2 xs={12} md={6}>
                    <FormControl fullWidth>
                      <Controller
                        name="startDate"
                        control={control}
                        defaultValue={dayjs(`${dayjs().year()}-01-01`)}
                        rules={{
                          required: true,
                          onChange: (event) => {
                            const currentValue: Dayjs = event.target.value;

                            clearErrors("endDate");

                            currentValue.add(11, "month").isBefore(dayjs())
                              ? setValue(
                                  "endDate",
                                  currentValue.add(11, "month")
                                )
                              : setValue("endDate", currentValue);
                          },
                        }}
                        render={({ field: { onChange, value, ref } }) => (
                          <CustomDatePicker
                            value={value}
                            label={t("Start-Date")}
                            info={t("Start-Date")}
                            required
                            handleOnChange={onChange}
                            defaultDate={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>

                  <Grid2 xs={12} md={6}>
                    <FormControl fullWidth>
                      <Controller
                        name="endDate"
                        control={control}
                        defaultValue={
                          dayjs(`${dayjs().year()}-12-01`).isAfter(
                            dayjs(),
                            "month"
                          )
                            ? dayjs(`${dayjs().year()}-01-01`)
                            : dayjs(`${dayjs().year()}-12-01`)
                        }
                        rules={{
                          onChange(event) {
                            clearErrors("endDate");

                            const endDate = dayjs(event.target.value);
                            if (
                              endDate.isAfter(dayjs()) ||
                              endDate.isBefore(startDate)
                            ) {
                              setValue("endDate", startDate);
                            }
                          },
                          validate: (value) => {
                            const start = dayjs(startDate);

                            const end = dayjs(value);

                            const dateDiff = end.diff(start, "month") ?? -1;

                            const isPassDateDiff =
                              dateDiff === 0 || dateDiff === 11 ? true : false;

                            return (
                              value == null ||
                              start.isSame(end, "month") ||
                              (start.isBefore(end, "month") &&
                                isPassDateDiff &&
                                !end.isAfter(dayjs(), "month")) ||
                              t("Validate-Date-Error")
                            );
                          },
                        }}
                        render={({
                          field: { onChange, value, ref },
                          fieldState: { error },
                        }) => (
                          <CustomDatePicker
                            value={value}
                            label={t("End-Date")}
                            info={t("End-Date")}
                            handleOnChange={onChange}
                            defaultDate={null}
                            inputRef={ref}
                            error={error}
                            required
                            minDate={startDate}
                            maxDate={
                              startDate &&
                              startDate
                                .add(11, "month")
                                .isBefore(dayjs(), "month")
                                ? startDate.add(11, "month")
                                : dayjs()
                            }
                            disableFuture
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>

                  <Grid2 xs={12} md={6}>
                    <CustomAutoComplete
                      required
                      rules={{
                        required: t("form-reqired-error", {
                          param: t("Indicator"),
                        }),
                      }}
                      name="indicatorRef"
                      control={control}
                      label={t("Indicator")}
                      info={t(
                        "Economic-Indicators-For-Calculating-Eco-Efficiency"
                      )}
                      placeholder={t("Select-Param", {
                        param: t("Indicator"),
                      })}
                      listMenuItem={optionEconomicIndicators}
                      defaultValue={""}
                    />
                  </Grid2>

                  <Grid2 xs={12} md={6}>
                    <CustomAutoComplete
                      rules={undefined}
                      name="baseYear"
                      control={control}
                      label={t("Base-Year")}
                      info={t("Base-Year-Period-description")}
                      placeholder={
                        baseYearIsDisabled
                          ? "-"
                          : t("Select-Param", {
                              param: t("Base-Year"),
                            })
                      }
                      listMenuItem={
                        baseYearOptions
                          ? baseYearOptions.map((i) => ({
                              nameEN: i.toString(),
                              nameTH: i.toString(),
                              value: i.toString(),
                            }))
                          : []
                      }
                      defaultValue={""}
                      disabled={baseYearIsDisabled}
                    />
                  </Grid2>

                  <Grid2 xs={12} md={6}>
                    <CustomAutoComplete
                      required
                      rules={{
                        required: t("form-reqired-error", {
                          param: t("Environment-Impact-Type"),
                        }),
                      }}
                      name="environmentalImpactType"
                      control={control}
                      label={t("Environment-Impact-Type")}
                      info={t("Environment-Impact-Type-Description")}
                      placeholder={t("Select-Param", {
                        param: t("Environment-Impact-Type"),
                      })}
                      listMenuItem={
                        environmentImpactTypeOptions
                          ? environmentImpactTypeOptions.map((i) => ({
                              nameEN: i,
                              nameTH: i,
                              value: i,
                            }))
                          : []
                      }
                      defaultValue={"Emission Scope 1-3"}
                    />
                  </Grid2>

                  <Hidden mdDown>
                    <Grid2 xs={6}></Grid2>
                  </Hidden>

                  <Grid2 xs={12} marginY={"20px"}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableColumnMenu
                      autoHeight
                      hideFooter
                      rowHeight={90}
                      localeText={{
                        noRowsLabel: t("noRowsLabel"),
                        noResultsOverlayLabel: t("noResultsOverlayLabel"),
                      }}
                      onCellKeyDown={(params, event, details) => {
                        if (params.field === "") {
                          event.stopPropagation();
                          return;
                        }
                      }}
                      sx={{
                        ...tableStyle(),
                      }}
                    />
                  </Grid2>
                </Grid2>

                <Grid2 xs={12}>
                  <InputReactHookForm
                    name={"remark"}
                    control={control}
                    label={t("Remark")}
                    placeholder={t("Remark")}
                    info={t("Remark")}
                    defaultValue={""}
                  />
                </Grid2>

                <Grid2 xs={12}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    type="submit"
                    name="add"
                    sx={{
                      marginTop: "20px",
                    }}
                  >
                    {editData ? t("Save") : t("Add")}
                  </Button>
                </Grid2>
              </form>
            </Box>
          </>
        }
      />
    </>
  );
};
