import { Autocomplete, TextField, FormControl, Box } from "@mui/material";
import LabelInput from "./LabelInput";
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { MenuData } from "./CustomSelect";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomFormHelperText } from "./CustomFormHelperText";

interface Props {
  name: string;
  control: Control<any, any>;
  rules:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
  label: string;
  info: string;
  placeholder: string;
  listMenuItem: MenuData[];
  required?: boolean;
  disabled?: boolean;
  defaultValue?: any;
}

export const CustomAutoComplete = (props: Props) => {
  const { i18n } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  return (
    <>
      <FormControl fullWidth>
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          defaultValue={props.defaultValue}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <LabelInput
                  label={props.label}
                  info={props.info}
                  required={props.required}
                />
                <Autocomplete
                  id={props.name}
                  key={props.name}
                  fullWidth
                  size="small"
                  disabled={props.disabled}
                  multiple={false}
                  onChange={(event, value) => {
                    onChange(value ? value.value : null);
                  }}
                  value={
                    value !== undefined
                      ? props.listMenuItem.find(
                          (option) => option.value === value
                        ) || null
                      : null
                  }
                  options={props.listMenuItem}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.value === "add" && (
                        <img
                          src="/img/add.svg"
                          alt="add"
                          style={{
                            marginRight: "8px",
                          }}
                        />
                      )}

                      {i18n.language.toLowerCase().includes("th")
                        ? option.nameTH
                        : option.nameEN}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value: any) => {
                    return option === value;
                  }}
                  getOptionKey={(options: MenuData) => options.value}
                  getOptionLabel={(options: MenuData) => {
                    if (i18n.language.toLowerCase().includes("th")) {
                      return options.nameTH ?? t("undefined");
                    }

                    return options.nameEN ?? t("undefined");
                  }}
                  getOptionDisabled={(options: MenuData) =>
                    options.disabled ?? false
                  }
                  popupIcon={<KeyboardArrowDownIcon />}
                  renderInput={(params) => (
                    <TextField
                      placeholder={props.placeholder}
                      error={error && true}
                      {...params}
                      style={{
                        height: "40px",
                      }}
                    />
                  )}
                />
                <CustomFormHelperText error={error} />
              </>
            );
          }}
        />
      </FormControl>
    </>
  );
};
