import { GridColDef, GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { EconomicIndicatorContext } from "../../../contexts/EconomicIndicatorContext";
import { tableStyle } from "../../emission/data-table/EmissionDataTableStyle";
import { Checkbox, IconButton, Switch } from "@mui/material";
import { CustomPagination } from "../../../components/datagrid/CustomPagination";
import { QuickSearchToolbar } from "./QuickSearchToolbar";
import { EconomicIndicator } from "../../../models/dto/response/ResponseCreateEconomicIndicators";
import { useTranslation } from "react-i18next";
import { EcoEfficiencyService } from "../../../service/ecoEfficiencyService";
import alertService from "../../../components/alert/alertService";
import { QuickUploadFile } from "../../../components/input/QuickUploadFile";
import { useAuth } from "../../../contexts/UserContext";

export const TableEconomicIndicator = () => {
  const { t } = useTranslation(["common", "economic", "report"], {
    nsMode: "fallback",
  });

  const {
    economicIndicators,
    setEconomicIndicators,
    setEditData,
    setModalIsOpen,
  } = EconomicIndicatorContext();

  const [rows, setRows] = useState<EconomicIndicator[]>(economicIndicators);

  const { isAdmin } = useAuth();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("ID"),
      disableColumnMenu: true,
      minWidth: 80,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value) => `EI-${value}`,
    },
    {
      field: "nameEn",
      headerName: `${t("Indicator-Name-En")}`,
      disableColumnMenu: true,
      minWidth: 180,
      type: "string",
      flex: 1,
    },
    {
      field: "nameTh",
      headerName: `${t("Indicator-Name-Th")}`,
      disableColumnMenu: true,
      minWidth: 180,
      type: "string",
      flex: 1,
    },
    {
      field: "unit",
      headerName: t("Unit"),
      disableColumnMenu: true,
      minWidth: 150,
      type: "string",
    },
    {
      field: "isRequireBaseYear",
      headerName: t("Base-Year"),
      disableColumnMenu: true,
      minWidth: 180,
      type: "boolean",
      renderCell: (row: any) => {
        return <Checkbox checked={row.value} disabled />;
      },
    },
    {
      field: "isDisable",
      headerName: t("activated"),
      disableColumnMenu: true,
      minWidth: 120,
      type: "boolean",
      renderCell: (params) => {
        return (
          <Switch
            id={"switch" + params.row._id}
            key={"switch" + params.row._id}
            checked={!params.value}
            onChange={() => enableDisableEconomicIndicators(params.row._id)}
            disabled={!isAdmin()}
          />
        );
      },
    },
    {
      field: "attachment",
      headerName: t("Files"),
      sortable: false,
      width: 80,
      align: "center",
      headerAlign: "center",
      getApplyQuickFilterFn: undefined,
      disableColumnMenu: true,
      renderCell: (params) => {
        const value = params.value ?? "";
        return (
          <QuickUploadFile
            value={value}
            id={params.row.id}
            quickUploadFile={(base64: string) =>
              quickUploadFile(base64, params.row._id)
            }
            disabled={!isAdmin()}
          />
        );
      },
    },
    {
      field: "",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      type: "actions",
      width: 50,
      getApplyQuickFilterFn: undefined,
      getActions: (params) => {
        return isAdmin()
          ? [
              <GridActionsCellItem
                icon={<img src="/img/edit-2.svg" alt="icon-edit" />}
                label="Edit"
                className="textPrimary"
                onClick={() => handleEditClick(params.row)}
                color="inherit"
                key={`${params.id}-icon-edit`}
                id={`${params.id}-icon-edit`}
              />,
            ]
          : [];
      },
    },
  ];

  const enableDisableEconomicIndicators = async (refId: string) => {
    const result = await EcoEfficiencyService.enableDisableEconomicIndicators(
      refId
    );

    if (result) {
      setEconomicIndicators(result);
      alertService.mixin(t("edit-successfully"));
    }
  };

  const quickUploadFile = async (base64: string, refId: string) => {
    const result = await EcoEfficiencyService.editEconomicIndicators(refId, {
      attachment: base64,
    });

    if (result) {
      alertService.mixin(t("edit-successfully"));
      setEconomicIndicators(result);
    }
  };

  const handleEditClick = (row: EconomicIndicator) => {
    setEditData(row);
    setModalIsOpen(true);
  };

  useEffect(() => {
    setRows(economicIndicators);
  }, [economicIndicators]);

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={60}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        slots={{
          toolbar: QuickSearchToolbar,
          pagination: CustomPagination,
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: `${t("search")}...`,
          noRowsLabel: t("noRowsLabel"),
          noResultsOverlayLabel: t("noResultsOverlayLabel"),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              variant: "outlined",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        sx={tableStyle()}
      />
    </>
  );
};
