import { Box, Divider, List } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useEffect } from "react";
import { routes } from "../../routeInfo";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../contexts/LayoutContext";
import SidebarItem from "./SidebarItem";
import LogoGradient from "./LogoGradient";
import LogoutModal from "../modal/LogoutModal";
import { NavLink } from "../../models/NavLink";
import NavComponent from "./NavComponent";
import { useNavigate } from "react-router-dom";
import { useAccount } from "../../contexts/AccountContext";
import { APP_VERSION } from "../../constants/carboncalVersion";

import "./index.css";

const sidebarSmallWidth = 90;
const sidebarFullWidth = 262;
const avatarIconWidth = 44;
const imgIconWidth = 24;

const overview: NavLink[] = [
  {
    path: "/overview/total-emissions",
  },
  {
    path: "/overview/scope-1",
  },
  {
    path: "/overview/scope-2",
  },
  {
    path: "/overview/scope-3",
  },
  // {
  //   path: "/overview/other",
  // },
];

const navLinks: NavLink[] = [
  {
    path: "/overview",
    children: overview.filter((e) => {
      if (process.env.REACT_APP_IS_OPEN_SCOPE_3 !== "true") {
        return e.path !== "/overview/scope-3";
      }

      return true;
    }),
  },
  {
    path: "/emission",
    children: [
      {
        path: "/emission/add",
      },
      {
        path: "/emission/import-data",
      },
      {
        path: "/emission/eco-efficiency",
      },
    ],
  },
  {
    path: "/report",
    children: [
      {
        path: "/report/tgo-cfo",
      },
      {
        path: "/report/summary",
      },
    ],
  },
  {
    path: "/setting",
    children: [
      {
        path: "/setting/activities-template",
      },
      {
        path: "/setting/economic-indicator",
      },
      {
        path: "/setting/access-control",
      },
      {
        path: "/setting/business-structure",
      },
      // {
      //   path: "/setting/accessility-and-permission",
      // },
    ],
  },
];

const Sidebar = () => {
  const {
    openSidebar,
    setOpenSidebar,
    openSignOut,
    setOpenSignOut,
    profile,
    setProfile,
  } = useContext(LayoutContext);
  const { user } = useAccount();
  const navigate = useNavigate();

  const { t } = useTranslation(["common"]);

  const onMouseEnter = () => {
    setOpenSidebar(true);
  };

  const onMouseLeave = () => {
    setOpenSidebar(false);
  };

  const onSignOutClick = () => {
    setOpenSignOut(true);
  };
  const onSignOutClose = () => {
    setOpenSignOut(false);
  };

  const handleCloseClick = () => {
    setOpenSidebar(false);
  };

  let logoType = "small";
  if (openSidebar) {
    logoType = "full";
  }

  const itempx = (sidebarSmallWidth - avatarIconWidth) / 2;

  const sidebarWidth = openSidebar ? sidebarFullWidth : sidebarSmallWidth;

  const navComponent = navLinks.map((link) => {
    return (
      <NavComponent
        key={link.path}
        link={link}
        parentOpen={openSidebar}
        index={0}
        routes={routes}
        width={avatarIconWidth}
        leftImgSize={imgIconWidth}
        leftAvatarSize={avatarIconWidth}
      />
    );
  });

  useEffect(() => {
    if (user) {
      setProfile(user!.userProfile);
    }
  }, [user]);

  return (
    <Box bgcolor={"var(--white)"}>
      <Box
        display={{
          xs: openSidebar ? "flex" : "none",
          md: "flex",
        }}
        flexDirection={"column"}
        position={"fixed"}
        width={{
          xs: "100%",
          md: `${sidebarWidth}px`,
        }}
        height={"100%"}
        zIndex={openSidebar ? 10 : 2}
        bgcolor={"var(--dark)"}
        borderRadius={"0 20px 20px 0"}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          transition: ".3s",
          overflowY: "auto",
        }}
      >
        <LogoGradient
          logoType={logoType}
          logoWidth={logoType === "small" ? "120px" : "144px"}
          marginTop={logoType === "small" ? "15%" : "0"}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: `${itempx}px`,
            gap: "25px",
            my: "32px",
            flexGrow: 1,
          }}
        >
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 0,
              gap: "25px",
              flexGrow: 1,
            }}
          >
            {navComponent}
          </List>
          {/* <SidebarItem
            className={"sidebar-menu"}
            opacity={openSidebar ? 1 : 0}
            text={t("Terms of Use & Privacy")}
            link={"/terms"}
          /> */}
          <Divider sx={{ background: "white" }} />
          <SidebarItem
            className="sidebar-avatar"
            link="/account"
            text={
              user && user.displayName
                ? user.displayName
                : user?.email ?? "User"
            }
            leftImgType="Avatar"
            leftImgAlt="User Profile"
            leftImgSize={imgIconWidth}
            leftAvatarSize={avatarIconWidth}
            onLeftImgClick={() => navigate("/account")}
            rightImg="/img/signout.svg"
            rightImgAlt="Sign out"
            onRightImgClick={onSignOutClick}
            leftImg={profile ?? undefined}
          />
        </Box>

        <small
          style={{
            opacity: 0.5,
            fontSize: "12px",
            textAlign: "center",
            paddingBottom: "8px",
          }}
        >{`V.${APP_VERSION}`}</small>

        <Box
          onClick={handleCloseClick}
          display={{ xs: "block", md: "none" }}
          sx={{
            position: "absolute",
            right: "20px",
            top: "20px",
            cursor: "pointer",
          }}
        >
          <CloseIcon />
        </Box>
      </Box>
      <LogoutModal
        title={t("Sign-out")}
        body={t("Sign-out-message")}
        buttonText={t("Yes")}
        open={openSignOut}
        onClose={onSignOutClose}
        onSubmit={onSignOutClose}
      />
    </Box>
  );
};

export default Sidebar;
