import {
  Box,
  Card,
  CardContent,
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";
import { ChartsAxisContentProps, BarSeriesType } from "@mui/x-charts";
import { TFunction } from "i18next";
import Series from "../../models/Series";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";
import React from "react";
import { TrendColumn } from "./TrendColumn";

const BarChartTooltip = (
  props: ChartsAxisContentProps,
  t: TFunction<[string, string], undefined>,
  subTotal?: number
) => {
  const index = props.dataIndex ?? 0;
  const series: Record<string, Series[]> = {};
  let totalValue = 0;
  props.series.forEach((item) => {
    const barItem = item as BarSeriesType;
    const data = item.data[index] ?? 0;
    item.data.forEach((data) => {
      if (typeof data == "number") {
        totalValue += data;
      }
    });
    const stack = barItem.stack ?? "";
    const old = series[stack];
    if (old === undefined) {
      series[stack] = [];
    }
    if (typeof data == "number") {
      series[stack].push({
        data,
        label: barItem.label,
        stack: barItem.stack,
        color: barItem.color,
      });
    }

    return [];
  });
  const seriesValue = Object.values(series);
  if (seriesValue.length === 0) return <Box></Box>;
  if (props.series.length === 1) {
    const item = seriesValue[0][0];
    return (
      <Card sx={{ bgcolor: "var(--white)", borderRadius: "8px" }}>
        <CardContent sx={{ display: "flex", gap: "12px" }}>
          <Box width={"24px"} height={"24px"} bgcolor={item.color} />
          <Box>
            <Typography variant="h3" width={"100%"}>
              {`${t(props.axisValue)}`}
            </Typography>
            <Box display={"flex"} gap={"12px"}>
              <Typography>{`${NumberFormatterUtils.numberFormat(
                // (item.data / totalValue) * 100
                subTotal
                  ? (item.data / subTotal) * 100
                  : (item.data / totalValue) * 100
              )}%`}</Typography>
              <Typography>{`${NumberFormatterUtils.numberFormat(
                item.data
              )} tCO2e`}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }
  const titleComponent = seriesValue
    .map((item) => item[0])
    .map((item, index) => {
      if (item !== undefined) {
        let percent;

        let stack = "";

        if (seriesValue.length === 1) {
          const sumValue = seriesValue[0]
            .map((item) => item.data)
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue
            );
          percent = subTotal
            ? (sumValue / subTotal) * 100
            : (sumValue / totalValue) * 100;
        } else {
          stack = item?.stack ?? "";
        }

        return (
          <TableCell key={`title${index}`} colSpan={2}>
            <Typography variant="h3">
              {`${t(props.axisValue)}`} {stack}
              {percent && `(${NumberFormatterUtils.numberFormat(percent)})%`}
            </Typography>
          </TableCell>
        );
      }
      return <TableCell key={`title${index}`} />;
    });
  const total = seriesValue.map((data) => {
    return data
      .map((item) => item.data)
      .reduce((item1, item2) => item1 + item2);
  });

  const totalComponent = total
    .map((item, index) => `${NumberFormatterUtils.numberFormat(item)} tCO2e`)
    .map((text, index, array) => {
      const total0 = total[0] ?? 0;

      const total1 = total[1] ?? 0;

      const trend = ((total1 - total0) / total0) * 100;

      return (
        <React.Fragment key={`total-fragment-${props.axisValue}-${index}`}>
          <TableCell
            key={`total-1${props.axisValue}${index}`}
            colSpan={array.length - 1 === index || index === 1 ? 1 : 2}
          >
            {text}
          </TableCell>

          {index === 1 && <TrendColumn trend={trend} />}
        </React.Fragment>
      );
    });
  const seriesTranspose = seriesValue[0].map((_, colIndex) =>
    seriesValue.map((row) => row[colIndex])
  );
  const details = seriesTranspose.map((item, index) => {
    const data = item
      .flatMap((data, index) => {
        const totalData = total[index];
        if (totalData === 0 || data === undefined) {
          return ["0%", "0 tC02e"];
        }
        return [
          `${NumberFormatterUtils.numberFormat(
            (data.data / totalData) * 100
          )}%`,
          `${NumberFormatterUtils.numberFormat(data.data)} tCO2e`,
        ];
      })
      .map((text, index) => {
        return <TableCell key={`details${index}`}>{text}</TableCell>;
      });
    return (
      <TableRow key={`detailrows${index}`}>
        <TableCell>
          <Box display={"flex"} gap={"12px"} alignItems={"center"}>
            <Box
              width={"24px"}
              height={"24px"}
              bgcolor={(item[item.length - 1] ?? item[0]).color}
            />
            {item[0].label && t(item[0].label)}
          </Box>
        </TableCell>
        {data}
      </TableRow>
    );
  });

  let trend = 0;

  const total0 = total[1] ?? 0;

  const total1 = total[2] ?? 0;

  trend = ((total1 - total0) / total0) * 100;

  trend = isNaN(trend) ? 0 : trend;

  return (
    <Box className="tooltip" bgcolor={"var(--white)"} borderRadius={"8px"}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              {titleComponent.length > 1 && <TableCell />}
              {titleComponent}
            </TableRow>
            {totalComponent.length > 1 && (
              <TableRow>
                <TableCell>
                  <Typography variant="h3">{t("Total")}</Typography>
                </TableCell>
                {totalComponent}

                <TrendColumn trend={trend} />
              </TableRow>
            )}
            {details}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BarChartTooltip;
