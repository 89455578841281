import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { EcoEfficiencyService } from "../service/ecoEfficiencyService";
import { EconomicIndicator } from "../models/dto/response/ResponseCreateEconomicIndicators";
import { EcoEfficiency } from "../models/dto/response/ResponseEcoEfficiency";

type EcoEfficiencyState = {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  baseYearOptions: number[];
  setBaseYearOptions: Dispatch<SetStateAction<number[]>>;
  environmentImpactTypeOptions: string[];
  economicIndicators: EconomicIndicator[];
  getBaseYear: (body: any, refId?: string) => Promise<void>;
  ecoEfficiency: EcoEfficiency[];
  setEcoEfficiency: Dispatch<SetStateAction<EcoEfficiency[]>>;
  editData: EcoEfficiency | undefined;
  setEditData: Dispatch<SetStateAction<EcoEfficiency | undefined>>;
  filterSubOrganization: string[] | undefined;
  setFilterSubOrganization: Dispatch<SetStateAction<string[] | undefined>>;
  masterEconomicIndicators: EconomicIndicator[];
};

export const Context = createContext<EcoEfficiencyState>(
  {} as EcoEfficiencyState
);

export const EcoEfficiencyProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const [baseYearOptions, setBaseYearOptions] = useState<number[]>([]);

  const [environmentImpactTypeOptions, setEnvironmentImpactTypeOptions] =
    useState<string[]>([]);

  const [ecoEfficiency, setEcoEfficiency] = useState<EcoEfficiency[]>([]);

  const [editData, setEditData] = useState<EcoEfficiency | undefined>();

  const [filterSubOrganization, setFilterSubOrganization] = useState<
    string[] | undefined
  >(undefined);

  const [economicIndicators, setEconomicIndicators] = useState<
    EconomicIndicator[]
  >([]);

  const [masterEconomicIndicators, setMasterEconomicIndicators] = useState<
    EconomicIndicator[]
  >([]);

  const getEcoEfficiency = async () => {
    const result = await EcoEfficiencyService.getEcoEfficiency();

    if (result) {
      setEcoEfficiency(result);
    }
  };

  const getBaseYear = async (body: any, refId?: string) => {
    const result = await EcoEfficiencyService.getBaseYear(body, refId);

    if (result) {
      setBaseYearOptions(result);
    }
  };

  const getEnvironmentalImpactTypes = async () => {
    const result = await EcoEfficiencyService.getEnvironmentalImpactTypes();

    if (result) {
      setEnvironmentImpactTypeOptions(result);
    }
  };

  const getEconomicIndicators = async () => {
    const result = await EcoEfficiencyService.getEconomicIndicators();

    if (result) {
      setEconomicIndicators(result.filter((i) => i.isDisable !== true));
      setMasterEconomicIndicators(result);
    }
  };

  useEffect(() => {
    getEcoEfficiency();
    getEnvironmentalImpactTypes();
    getEconomicIndicators();
  }, []);

  return (
    <Context.Provider
      value={{
        modalIsOpen,
        setModalIsOpen,
        baseYearOptions,
        environmentImpactTypeOptions,
        economicIndicators,
        getBaseYear,
        setBaseYearOptions,
        ecoEfficiency,
        setEcoEfficiency,
        editData,
        setEditData,
        filterSubOrganization,
        setFilterSubOrganization,
        masterEconomicIndicators,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const EcoEfficiencyContext = () => useContext(Context);
